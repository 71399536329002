import * as React from 'react';

import {Field, FieldProps, Form, Formik, FormikProps} from 'formik';

import {Company} from "@app/objects/Company";
import Autocomplete, {Option} from "@common/react/components/Forms/Autocomplete/Autocomplete";

import CommonFilters, {FilterBaseState, RemoveButton} from "@app/components/UI/CommonFilter/CommonFilter";
import {User, UserRole} from "@app/objects/User";
import {getInjuryStatusName, Injury, InjuryStatus} from "@app/objects/Injury";
import {connect} from "react-redux";
import {ApplicationState} from "@app/store";
import {SpecialCompanyType} from "@app/objects/CompanyType";
import Datepicker, {disabledFrom, disabledTo} from "@common/react/components/Forms/Datepicker/Datepicker";
import {dateFormat, getUserName, getUserNameWithEmail} from "@app/components/Utils/Utils";

type ComponentsProps = ReduxProps & OwnProps;

interface OwnProps {
	onApply: <EmployeesFiltersValues>(values) => void
}

interface ReduxProps {
	user: User | null;
}

export interface EmployeesFiltersValues {
	employer: Company | null | undefined;
	referral: Company | null | undefined;
	injuryStatus: InjuryStatus | null | undefined;
	currentRestrictionDate: number[] | null[];
	assignedPerson: User | null | undefined;
}

const injuryStatusOpenValue = 6;

class EmployeesFilters extends CommonFilters<ComponentsProps, FilterBaseState, EmployeesFiltersValues> {
	constructor(props: ComponentsProps) {
		super(props);

		const that = this;

		this.state = {
			filters: {
				employer: {
					id: 1,
					caption: 'Employer',
					defaultValue: null,
					value: null,
					getValue: function() {
						return  <span className="filters-component__item-value">{this.value && (this.value as Company).name}<RemoveButton click={() => that.removeFilter('employer')}/></span>
					}
				},
				referral: {
					id: 2,
					caption: 'Referral Source',
					defaultValue: null,
					value: null,
					getValue: function() {
						return  <span className="filters-component__item-value">{this.value && (this.value as Company).name}<RemoveButton click={() => that.removeFilter('referral')}/></span>
					}
				},
				injuryStatus: {
					id: 3,
					caption: 'Injury Status',
					defaultValue: -1,
					value: -1,
					getValue: function() {
						return <span className="filters-component__item-value">{+this.value === injuryStatusOpenValue ? 'Open' : getInjuryStatusName(that.props.user, this.value)}<RemoveButton click={() => that.removeFilter('injuryStatus')}/></span>;
					}
				},
				assignedPerson: {
					id: 4,
					caption: 'Assigned Person',
					defaultValue: null,
					value: null,
					getValue: function() {
						return  <span className="filters-component__item-value">{this.value && getUserName(this.value as User)}<RemoveButton click={() => that.removeFilter('assignedPerson')}/></span>
					}
				},
				currentRestrictionDate: {
					id: 5,
					caption: 'Referral Date',
					defaultValue: [null, null],
					value: [null, null],
					getValue: function() {
						if (this.value[0] !== null && this.value[1] !== null) {
							return  <span className="filters-component__item-value">{dateFormat(this.value[0])} - {dateFormat(this.value[1])}<RemoveButton click={() => that.removeFilter('currentRestrictionDate')}/></span>
						} else if (this.value[0] !== null) {
							return <span className="filters-component__item-value">From {dateFormat(this.value[0])}<RemoveButton click={() => that.removeFilter('currentRestrictionDate')}/></span>
						} else {
							return <span className="filters-component__item-value">To {dateFormat(this.value[1])}<RemoveButton click={() => that.removeFilter('currentRestrictionDate')}/></span>
						}

					},
					check:function() {
						return this.value[0] !== this.defaultValue[0] || this.value[1] !== this.defaultValue[1];
					}
				}
			},
			visible: false,
		};
		
		this.resetFilters = this.resetFilters.bind(this);
	}

	getFiltersContent() {
		const {filters} = this.state;
		
		const {user} = this.props;
		
		const getFormContainer = (triggerNode: Element) => document.getElementById('filters-component__form') || document.body;
		
		const isNotExternal: boolean = user !== null && user.role !== UserRole.External;

		return <div className="filters-component__popup clearfix">
			<Formik
				initialValues={
					{
						employer: filters.employer.value,
						referral: filters.referral.value,
						injuryStatus: filters.injuryStatus.value,
						currentRestrictionDate: filters.currentRestrictionDate.value,
						assignedPerson: filters.assignedPerson.value
					}
				}
				onSubmit={this.applyFilters}
				enableReinitialize={true}
				render={(formikBag: FormikProps<EmployeesFiltersValues>) => (
					<Form id="filters-component__form" className="filters-component__form">
						<div className="row">
							<Field
								name="employer"
								render={({ field, form }: FieldProps<EmployeesFiltersValues>) => (
									<div className={`form-group col-sm-4`}>
										<label>Employer</label>
										<Autocomplete type="companyList"
										              onSelect={(value, option) => {formikBag.setFieldValue('employer', option.props.item); }}
										              onChange={(value = '') => { value === '' && formikBag.setFieldValue('employer', null) }}
										              value={formikBag.values.employer ? formikBag.values.employer.name : ''}
										              paramName="name"
										              antdProps={{
											              getPopupContainer: getFormContainer
										              }}
										              params={{
										              	companyType: SpecialCompanyType.Employer
										              }}
										/>
									</div>
								)}
							/>
							{isNotExternal && <Field
								name="referral"
								render={({ field, form }: FieldProps<EmployeesFiltersValues>) => (
									<div className={`form-group col-sm-4`}>
										<label>Referral Source</label>
										<Autocomplete type="companyList"
										              onSelect={(value, option) => {formikBag.setFieldValue('referral', option.props.item); }}
										              onChange={(value = '') => { value === '' && formikBag.setFieldValue('referral', null) }}
										              value={formikBag.values.referral ? formikBag.values.referral.name  : ''}
										              paramName="name"
										              params={{
										              	companyType: SpecialCompanyType.Referral
										              }}
										              antdProps={{
											              getPopupContainer: getFormContainer
										              }}
										/>
									</div>
								)}
							/>}
							<Field
								name="injuryStatus"
								children={({ field, form }: FieldProps<EmployeesFiltersValues>) => (
									<div className={`form-group col-sm-4`}>
										<label htmlFor="injuryStatus">Status</label>
										<div className="is-relative">
											{user && user.role !== UserRole.External ?
												<select className="form-control input-sm" id="injuryStatus" {...field}>
													<option value={-1}>All</option>
													<option value={InjuryStatus.New}>New Placement Received</option>
													<option value={InjuryStatus.InProgress}>Locating Offsite Job Location</option>
													<option value={InjuryStatus.FollowUp}>Follow-up</option>
													<option value={InjuryStatus.Pending}>Pending</option>
													<option value={InjuryStatus.Closed}>Closed</option>
												</select>
												:
												<select className="form-control input-sm" id="injuryStatus" {...field}>
													<option value={-1}>All</option>
													<option value={injuryStatusOpenValue}>Open</option>
													<option value={InjuryStatus.Pending}>Pending</option>
													<option value={InjuryStatus.Closed}>Closed</option>
												</select>
											}
										</div>
									</div>
								)}
							/>
							{!isNotExternal && <div className="form-group col-sm-4 filters-component__date">
									<label>Referral Date</label>
								<div className="row">
									<div className="col-md-12">
										<div className="col-sm-12 col-md-6 DateFrom">
											<Datepicker
												value={formikBag.values.currentRestrictionDate[0]}
												format="MM/DD/YY"
												onChange={date => formikBag.setFieldValue('currentRestrictionDate.0', date ? new Date(date).setHours(0,0,0,0) : date)}
												antdProps={{
													placeholder: "From",
													getCalendarContainer: getFormContainer
												}}
											/>
										</div>
										<div className="col-sm-12 col-md-6 DateTo">
											<Datepicker
												value={formikBag.values.currentRestrictionDate[1]}
												format="MM/DD/YY"
												onChange={date => formikBag.setFieldValue('currentRestrictionDate.1', date ? new Date(date).setHours(24,0,0,-1) : date)}
												antdProps={{
													placeholder: "To",
													getCalendarContainer: getFormContainer
												}}
											/>
										</div>
										</div>
									</div>
								</div>
							}
						</div>
						{isNotExternal && <div className="row">
							<Field 
								name="assignedPerson"
								render={({ field, form }: FieldProps<Injury>) => (
								<div className={`form-group col-sm-4`}>
									<label>Assigned Person</label>
									<Autocomplete type="userList"
									              renderOption={(item: User) => {
										              const name = getUserNameWithEmail(item);
										              const itemProps = {item: item};
										              
										              return <Option key={item.id + ''} value={item.id + ''} title={name} {...itemProps}>{name}</Option>
									              }}
									              onSelect={(value, option) => {formikBag.setFieldValue('assignedPerson', option.props.item);}}
									              onChange={(value = '') => { value === '' && formikBag.setFieldValue('assignedPerson', null); }}
									              value={formikBag.values.assignedPerson ? getUserName(formikBag.values.assignedPerson) : ''}
									              params={{
										              roles: [UserRole.Admin, UserRole.Internal],
									              }}
									              antdProps={{
										              getPopupContainer: getFormContainer
									              }}
									/>
								</div>
								)}
							/>
							<div className="form-group col-sm-6 filters-component__date">
								<label>Referral Date</label>
								<div className="row">
									<div className="col-md-12">
									<div className="col-sm-12 col-md-6 DateFrom">
										<Datepicker
											disabledDate={disabledTo(formikBag.values.currentRestrictionDate[1])}
											value={formikBag.values.currentRestrictionDate[0]}
											format="MM/DD/YY"
											onChange={date => formikBag.setFieldValue('currentRestrictionDate.0', date ? new Date(date).setHours(0,0,0,0) : date)}
											antdProps={{
												placeholder: "From",
												getCalendarContainer: getFormContainer
											}}
										/>
									</div>
									<div className="col-sm-12 col-md-6 DateTo">
										<div className="FilterDateTo">
										<Datepicker
											disabledDate={disabledFrom(formikBag.values.currentRestrictionDate[0])}
											value={formikBag.values.currentRestrictionDate[1]}
											format="MM/DD/YY"
											onChange={date => formikBag.setFieldValue('currentRestrictionDate.1', date ? new Date(date).setHours(24,0,0,-1) : date)}
											antdProps={{
												placeholder: "To",
												getCalendarContainer: getFormContainer
											}}
											/>
										</div>
									</div>
									</div>
								</div>
							</div>
						</div>}
						{this.filtersButtons()}
					</Form>
				)}
			/>
		</div>;
	}
}

export default connect<ReduxProps, {}, OwnProps>(
	(state: ApplicationState) => ({
		user: state.login.user
	}),
	{}
)(EmployeesFilters);
