import * as React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { ApplicationState } from '@app/store/index';
import * as PagesState from '@app/store/ItemList';
import { ExtendableItemsPage } from '@app/components/Pages/ItemsPage';
import { User, UserRole } from "@app/objects/User";
import { Employee } from "@app/objects/Employee";
import { dateFormat, getExportUrl, getUserName, getUserNameWithEmail, printPage } from "@app/components/Utils/Utils";
import { getInjuryStatusName, Injury, InjuryStatus } from "@app/objects/Injury";
import EmployeesFilters, { EmployeesFiltersValues } from "@app/components/Pages/Employees/EmployeesFilters"
import { ItemState } from "@app/store/Item";
import Popover from 'antd/lib/popover';
import Modal from "antd/lib/modal";
import ReportViewCase from "@app/components/Pages/Reports/ReportViewCase";
import SimpleSearchInput from "@common/react/components/Forms/SimpleSearchInput/SimpleSearchInput";
import { request } from '@app/components/Api';
import { AttorneyType, Attorney } from "@app/objects/Attorney";


interface EmployeesProps {
    user: User | null,
    report: ItemState<Injury>
}

interface State {
    visible: boolean;
    report: Injury | null;
    Attorney: Attorney | null;
    Adjuster: Attorney | null;
}

class Employees extends ExtendableItemsPage<Employee, EmployeesProps, State> {
    constructor(props: any) {
        super(props);

        this.additionalParams = {
            //withCompany: true,
            withOpenInjury: true,
            column: [{
                caption: "case",
                direction: 2
            }],
            //withReferralSource: true
        };

        this.state = {
            visible: false,
            report: null,
            Attorney: null,
            Adjuster: null
        };

        //const styles: { [key: string]: React.CSSProperties } = {
        //    drftbtn: {
        //        margin- right: 10px;
        //        color: '#ff0000'
        //    },
        //    completebtn: {
        //        background: '#008000',
        //        color: '#ffffff'
        //    }
        //};

        //todo: what it is supposed to be in columns?

        this.columns = [
            {
                title: 'Name',
                dataIndex: 'fullName',
                render: (text: string, record: Employee) => this.renderMobileCell('Name', getUserName(record)),
                sorter: true
            },
            {
                title: 'VCC Case #',
                dataIndex: 'case',
                render: (text: string, record: Employee) => {
                    const injury = this.getOpenInjury(record);
                    const user = this.props.user;

                    return this.renderMobileCell('VCC Case #', injury && user && <span className="nobr">
                        {user.role !== UserRole.External
                            ? <Link to={`/employe-editor/${record.id}?case=${injury.vccCase}`}>{injury.vccCase}</Link>
                            : injury.vccCase
                        }
                    </span>
                    )
                },
                sorter: true
            },
            {
                title: 'Referral Date',
                dataIndex: 'referralDate',
                render: (text: string, record: Employee) => {
                    const injury = this.getOpenInjury(record);
                    return this.renderMobileCell('Referral Date', injury && injury.referralDate ? dateFormat(injury.referralDate) : '-')
                },
                sorter: true
            },
            {
                title: 'Employer',
                dataIndex: 'employer',
                render: (text: string, record: Employee) => this.renderMobileCell('Employer', record.companyName),
                sorter: true
            },
            {
                title: 'Referral Source',
                dataIndex: 'referral',
                render: (text: string, record: Employee) => {
                    return this.renderMobileCell('Referral Source', record.referralName)
                },
                sorter: true
            },
            {
                title: 'Assigned To',
                dataIndex: 'assignedPerson',
                render: (text: string, record: Employee) => {
                    const injury = this.getOpenInjury(record);
                    return this.renderMobileCell('Assigned To', injury && injury.assignedPerson ? getUserNameWithEmail(injury.assignedPerson) : '-')
                },
                sorter: true
            },
            {
                title: 'Unread Emails',
                dataIndex: 'unreadEmailsCount',
                render: (text: string, record: Employee) => {
                    const injury = this.getOpenInjury(record);
                    return this.renderMobileCell('Unread Emails', injury && <Link to={'/employe-editor/' + record.id + '?case=' + injury.vccCase + '&emails'}>{text}</Link>)
                },
                sorter: true
            },
            {
                title: 'Case Status',
                dataIndex: 'status',
                render: (text: string, record: Employee) => {
                    const injury = this.getOpenInjury(record);

                    return this.renderMobileCell('Status',
                        injury
                            ? <div>
                                {getInjuryStatusName(this.props.user, injury.injuryStatus)}
                                {injury.injuryStatus === InjuryStatus.Pending && injury.lastStatusHistory && injury.lastStatusHistory.pendingReason &&
                                    <span> - {injury.lastStatusHistory.pendingReason.name}
                                        {injury.lastStatusHistory.note && <Popover title="Note" content={<div>{injury.lastStatusHistory.note}</div>}>
                                            <i className="fa fa-info-circle popover-icon" aria-hidden="true"></i>
                                        </Popover>}
                                    </span>
                                }
                            </div>
                            : 'Closed'

                    )
                },
                sorter: true
            },
            {
                title: '',
                key: 'actions',
                render: (text, record) => (
                    <div className="table-actions flex-end">
                        {this.props.user && !record.isCompleted &&
                            /*<button className="btn btn-sm btn-default" style={styles.drftbtn} title="File in draft"><i className="fa fa-file" title="File in draft"></i></button>*/
                            <p className="drftbtn"><b>In draft</b></p>
                        }
                        {/*{this.props.user && this.props.user.role == UserRole.External && record.isCompleted &&*/}
                        {/*    <button className="btn btn-sm btn-default" style={styles.completebtn} title="File Completed"><i className="fa fa-book" title="File Completed"></i></button>*/}
                        {/*}*/}

                        {record.injury &&
                            <button className="btn btn-sm btn-default" title="View Case" onClick={e => this.viewCase(record.injury)}><i className="fa fa-eye"></i></button>}
                        {this.props.user && this.props.user.role !== UserRole.External && <div>
                            <Link className="btn btn-sm btn-default ml10" title="Edit" to={'/employe-editor/' + record.id}><i className="fa fa-pencil"></i></Link>
                            {record.injury && <a className="btn btn-sm btn-default hr-print" title="Print" target="_blank" href={'/getInjury?id=' + record.injury.id}><i className="fa fa-print"></i></a>}
                           
                        </div>}
                        {this.props.user && (this.props.user.role !== UserRole.External || !record.isCompleted) &&
                            <div>
                            <button className="btn btn-sm btn-danger ml10" title="Delete" onClick={e => this.handleDelete(e, record)}><i className="fa fa-trash"></i></button>
                        </div>}
                        {this.props.user && this.props.user.role == UserRole.External && !record.isCompleted && <div>
                            <Link className="btn btn-sm btn-default ml10" title="Edit" to={'/referral-editor/' + record.id}><i className="fa fa-pencil"></i></Link>
                        </div>}
                        {this.props.user && this.props.user.role == UserRole.External && record.injury && <a className="btn btn-sm btn-default ml10" title="Print details" target="_blank" onClick={e => this.loadPrint(record)}><i className="fa fa-print"></i></a>}
                    </div>
                )
            }
        ];

        if (this.props.user && this.props.user.role === UserRole.External) {
            this.columns = this.columns.filter(column => column.dataIndex !== 'assignedPerson' && column.dataIndex !== 'unreadEmailsCount');
        }

        this.type = 'employe';
        this.store = 'employees';
        this.path = 'employeList';
        this.listPath = 'employees';
        this.editorPath = this.props.user && this.props.user.role === UserRole.External ? 'referral-editor' : 'employe-editor';
        this.editorCaption = this.props.user && this.props.user.role === UserRole.External ? 'Create Referral' : 'Add Employee';
        this.caption = 'Employees';
        this.tableClassName = 'table-mobile employees-top';

        this.getFiltersRender = this.getFiltersRender.bind(this);
        this.onApplyFilters = this.onApplyFilters.bind(this);
        this.viewCase = this.viewCase.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
        this.getUrl = this.getUrl.bind(this);
    }

    getOpenInjury(record: Employee): Injury | null {
        return record.injury;
        //return record.injuries && record.injuries.list && record.injuries.list[0] ? record.injuries.list[0] : null;
    }

    getUrl() {

        const url = '/getEmployees';
        const params = ['companyId', 'referralSource', 'status', 'assignedTo', 'from', 'to'];

        return getExportUrl(url, params, this.additionalParams);
    }

    loadPrint(value: Employee) {
        if (value.injury && value.injury.employeeAttorneyId) {
            this.findAttorney(value.injury.employeeAttorneyId)
        }
        if (value.injury && value.injury.claimAdjusterId) {
            this.findAdjuster(value.injury.claimAdjusterId)
        }
        if (value.id > 0) {
            request<Employee>('getEmploye', {
                id: value.id
            }).then((response: Employee) => {
                this.PrintElem(response);
            });
        }
    }

    PrintElem(value: Employee) {
        

        var mywindow = window.open('', 'PRINT', 'height=600,width=800');
        if (mywindow) {

            var _injuryDate = (value.injury && value.injury.date ? dateFormat(value.injury.date) : "");
            var _nextDrAppt = (value.injury && value.injury.nextDrAppt ? dateFormat(value.injury.nextDrAppt) : "");
            var _currentRestrictionDate = (value.injury && value.injury.currentRestrictionDate ? dateFormat(value.injury.currentRestrictionDate) : "");
            var _lastRestrictionDate = (value.injury && value.injury.lastRestrictionDate ? dateFormat(value.injury.lastRestrictionDate) : "");
            var gender = value.gender == 1 ? 'Female' : 'Male';
            var _salutation = value.salutation == 1 ? "Mr." : value.salutation == 2 ? "Ms." : value.salutation == 3 ? "Mrs." : ""
            var _occupation = value.occupation ? value.occupation.name : "";
            //None = 0,
            //Right = 1,
            //Left = 2,
            //Bilateral = 3

            //var _subtype = value.injury && value.injury.subtype == 0 ? "None" : value.injury.subtype == 1 ? "Right" : value.injury.subtype == 0 ? ""
            var sMessage = "";
            sMessage = "<table width='100%' border='0' style='page-break-after: always; page-break-inside: avoid; font-family:Arial; font-size:12px;'>";
            if (value != null) {
                sMessage += "<tr><td><b>Salutation:</b> " + _salutation + "</td><td><b>First Name:</b> " + value.firstName + "</td><td><b>Last Name:</b> " + value.lastName + "</td> </tr>";
                sMessage += "<tr><td><b>Work Type:</b> " + value.payrollPeriod + "</td><td><b>Age:</b> " + value.age + "</td><td><b>Gender:</b> " + gender + "</td></tr>";
                sMessage += "<tr><td><b>Occupation:</b> " + _occupation + "</td><td><b>Email:</b> " + value.email + "</td><td><b>Phone:</b> " + value.phone + "</td></tr>";
                sMessage += "<tr><td><b>Company:</b> " + value.companyName + " </td><td><b>Address:</b> " + value.address + " </td><td><b>Mobile:</b> " + value.mobile + " </td></tr>";
                sMessage += "<tr><td><b>Apartment:</b> " + value.apartment + " </td><td><b>City:</b> " + value.city + " </td><td><b>State:</b> " + value.state + "</td></tr>";
                sMessage += "<tr></tr>";
                sMessage += "<tr></tr>";
                sMessage += "<tr><td colspan='3' style='text-align: center; font-size: 16px;'><b>Injury</td></tr>";
                if (value.injury) {
                    sMessage += "<tr><td><b>Case #:</b> " + value.injury.vccCase + "</td><td colspan='2'><b>Claim Number:</b> " + value.injury.claimNumber + "</td> </tr>";
                    sMessage += "<tr><td><b>Date of Injury:</b> " + _injuryDate + "</td><td><b>Injury Type:</b> " + value.injury.injuryType.name + "</td><td><b>Injury Subtype:</b> " + value.injury.subtype + "</td></tr>";
                    sMessage += "<tr><td colspan='2'><b>Days/Time Worked:</b> " + value.injury.daysTimeWorked + "</td><td><b>Weekly Hours Worked:</b> " + value.injury.preInjuryWeeklyHoursWorked + "</td></tr>";
                    sMessage += "<tr><td><b>AWW Rate:</b> " + value.injury.awwRate + "</td><td><b>Comp Rate:</b> " + value.injury.compRate + "</td><td><b>ALD Hourly Rate:</b> " + value.injury.hourRateALD + "</td> </tr>";
                    sMessage += "<tr><td><b>Physician:</b> " + value.injury.byPhysician + "</td><td><b>Next Dr. Appt:</b> " + _nextDrAppt + "</td><td><b>Restriction Date:</b> " + _currentRestrictionDate + "</td></tr>";
                    sMessage += "<tr><td><b>Last Restriction Date:</b> " + _lastRestrictionDate + "</td><td><b>State Jurisdiction:</b> " + value.injury.stateJurisdiction + "</td></tr>";
                    sMessage += "<tr><td><b>Placement Fee:</b> " + value.injury.placementFee1 + "</td> <td colspan='2'><b>Medical Restrictions:</b> " + value.injury.currentMedicalRestrictions + "</td></tr>";
                    sMessage += "<tr><td><b>2nd Placement Fee:</b> " + value.injury.placementFee2 + "</td><td colspan='2'><b>Trans Issue Type:</b> " + value.injury.transIssueType + "</td></tr>";
                    sMessage += "<tr><td><b>3rd Placement Fee:</b> " + value.injury.placementFee3 + "</td><td colspan='2'><b>Non Profit Additional Instructions:</b> " + value.injury.nonProfitAdditionalInstructions + "</td></tr>";
                    sMessage += "<tr><td><b>Job Task:</b> " + value.injury.jobTask + "</td><td><b>Time Requirements:</b> " + value.injury.timeRequirements + "</td><td><b>Report To:</b> " + value.injury.reportTo + "</td></tr>";
                    sMessage += "<tr><td><b>Physical Requirements:</b> " + value.injury.physicalRequirements + "</td></tr>";
                }
                sMessage += "<tr></tr>";
                sMessage += "<tr></tr>";
                sMessage += "<tr><td colspan='3' style='text-align: center; font-size: 16px;'><b>Employee Attorney</td></tr>";

                if (this.state.Attorney) {
                    sMessage += "<tr><td><b>First Name:</b> " + this.state.Attorney.firstName + "</td><td><b>Last Name:</b>" + this.state.Attorney.lastName + "</td> <td><b>Email:</b> " + this.state.Attorney.email + "</td></tr>";
                    sMessage += "<tr><td><b>Law Firm:</b> " + this.state.Attorney.lawFirm + "</td><td><b>Address:</b> " + this.state.Attorney.address + "</td><td><b>City:</b>" + this.state.Attorney.city + "</td> </tr>";
                    sMessage += "<tr><td><b>State:</b> " + this.state.Attorney.state + "</td><td><b>Zip:</b> " + this.state.Attorney.zip + "</td><td><b>Phone:</b> " + this.state.Attorney.phone + "</td></tr>";
                    sMessage += "<tr><td><b>Fax:</b>" + this.state.Attorney.fax + "</td> <td><b>Extension:</b> " + this.state.Attorney.extension + "</td><td></td></tr>";
                }
                sMessage += "<tr></tr>";
                sMessage += "<tr></tr>";
                sMessage += "<tr><td colspan='3' style='text-align: center; font-size: 16px;'><b>Claim Adjuster</b></td></tr>";
                if (this.state.Adjuster) {
                    sMessage += "<tr><td><b>First Name:</b> " + this.state.Adjuster.firstName + "</td><td><b>Last Name:</b>" + this.state.Adjuster.lastName + "</td> <td><b>Email:</b> " + this.state.Adjuster.email + "</td></tr>";
                    sMessage += "<tr><td><b>Phone:</b> " + this.state.Adjuster.phone + "</td><td><b>Extension:</b> " + this.state.Adjuster.extension + "</td><td><b>Insurance Co/TPA:</b> " + this.state.Adjuster.insuranceCompany.name + "</td></tr>";
                }
            }
            sMessage += "</table>";


            mywindow.document.write('<html><head><title style="font-size: 20px;">Employee Referral</title>');
            mywindow.document.write('</head><body>');
            //mywindow.document.write('<h1>Girish</h1>');
            mywindow.document.write(sMessage);
            mywindow.document.write('</body></html>');

            mywindow.document.close(); // necessary for IE >= 10
            mywindow.focus(); // necessary for IE >= 10*/

            mywindow.print();
            mywindow.close();
        }
        return true;
    }

    getFiltersRender(): JSX.Element {
        return <div className="list-filters">
            <div className="clearfix employees-list__search-container">
                <form onSubmit={this.handleFilter}>
                    <div className="employees-list__search">
                        <SimpleSearchInput onChange={this.handleChange} name="text" />
                    </div>
                    <div className="pull-right filterbtn ml10">
                        <button className="btn btn-default" type="button" title="Print" onClick={printPage}><i className="fa fa-print" /></button>
                        <a className="btn btn-success" target="_blank" href={this.getUrl()}><i className="fa fa-file-excel-o" /></a>
                    </div>
                </form>
                <EmployeesFilters onApply={this.onApplyFilters} />
                <div id="someHtml" style={{ display: 'none' }}></div>
            </div>
            <Modal
                title={this.state.report ? this.state.report.vccCase : ''}
                visible={this.state.visible}
                onCancel={this.closeDialog}
                width={600}
                footer={null}
            >
                <ReportViewCase reportId={this.state.report ? this.state.report.id : -1} />
            </Modal>
        </div>
    }

    onApplyFilters(values: EmployeesFiltersValues) {
        this.additionalParams = {
            ...this.additionalParams,
            ...{
                companyId: values.employer ? values.employer.id : 0,
                referralSource: values.referral ? values.referral.id : 0,
                status: values.injuryStatus ? +values.injuryStatus : 0,
                from: values.currentRestrictionDate[0] ? values.currentRestrictionDate[0] : null,
                to: values.currentRestrictionDate[1] ? values.currentRestrictionDate[1] : null,
                assignedTo: values.assignedPerson ? values.assignedPerson.id : 0,


            }
        } as any;

        this.props.reqPages(this.store, this.path, this.type, { page: 1, ...this.additionalParams });
    }

    viewCase(injury: Injury | null) {
        console.log(injury)
        this.setState({
            report: injury,
            visible: true
        });
    }

    closeDialog() {
        this.setState({
            visible: false,
        })
    }

    // -------------- Find the claim detials--------------//
    findAttorney(AttorneyId: number) {
        if (AttorneyId > 0)
            request<Attorney>('getAttorney', {
                id: AttorneyId
            }).then((response: Attorney) => {
                this.setState({ Attorney: response });
            });
    }

    findAdjuster(AdjusterId: number) {
        if (AdjusterId > 0)
            request<Attorney>('getAttorney', {
                id: AdjusterId
            }).then((response: Attorney) => {
                this.setState({ Adjuster: response });
            });
    }
    // -------------- End Find the claim detials--------------//

}

export default connect(
    (state: ApplicationState) => ({
        ...state.employees, // Selects which state properties are merged into the component's props
        user: state.login.user
    }),
    PagesState.getActionCreators<User>()                 // Selects which action creators are merged into the component's props
)(Employees as any) as typeof Employees;